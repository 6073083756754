import React from "react";
import cx from "classnames";
import speechmark from "@images/icn-speechmark.svg";
import SVG from "@components/SVG";
import BackgroundImage from "gatsby-background-image";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { IStructuredQuote } from "gatsby-node";

interface IStructuredQuoteProps extends IStructuredQuote {
  quote: string;
  quoteImgBackground: IGatsbyImageData;
  className: string;
}

const StructuredQuote: React.FC<IStructuredQuoteProps> = ({
  quote,
  quoteImgBackground,
  className,
}) => {
  const bg = convertToBgImage(getImage(quoteImgBackground));
  return (
    <div className={cx("", className)}>
      <div className="relative mb-14">
        <span className="w-[calc(50%-50px)] h-[1px] bg-slateGrey absolute top-1/2 right-0 " />
        <span className="w-[calc(50%-50px)] h-[1px] bg-slateGrey absolute top-1/2 left-0 " />
        <div className=" w-24 h-16 absolute left-[calc(50%-3rem)]" />
        <div className="block w-16 h-16 relative mx-auto">
          <BackgroundImage
            {...bg}
            className="w-full h-full  absolute left-0 z-0 pointer-events-none select-none"
            style={{ position: "absolute" }}
          />
          <SVG
            src={speechmark}
            className={` h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white group-hover:text-seaSerpant transition duration-300`}
          />
        </div>
      </div>
      <span className=" text-primary text-2xl lg:text-3xl">{quote}</span>
      <div className="w-full h-[1px] bg-slateGrey mt-20" />
    </div>
  );
};

export default StructuredQuote;
