import Paragraph from "@components/Paragraph";
import SocialLinks from "@components/SocialLinks";
import Title from "@components/Title";
import cx from "classnames";
import BackgroundImage from "gatsby-background-image";
import {
  GatsbyImage,
  GatsbyImageProps,
  getImage,
  ImageDataLike,
} from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from "react";

interface IAuthorCardProps {
  name: string;
  image: ImageDataLike;
  email: string;
  blurb: string;
  className: string;
  socials: any; // TODO types
  brandingSocialFrame: any; // TODO types
}

const AuthorCard: React.FC<IAuthorCardProps> = ({
  name,
  image,
  email,
  blurb,
  className,
  socials,
  brandingSocialFrame,
}) => {
  const author = getImage(image);
  const bg = convertToBgImage(getImage(brandingSocialFrame));

  return (
    <div className="my-10 xl:my-16">
      <div className={cx(`bg-light p-6 grid grid-cols-1 w-11/12`, className)}>
        <div className="flex flex-col sm:gap-4 justify-center items-center justify-items-center sm:grid sm:grid-cols-2 lg:gap-10 md:pb-0 md:grid-cols-author">
          <Title
            {...{
              type: 2,
              className: "sm:hidden mb-10 text-3xl sm:text-4xl text-center",
              color: "white",
            }}
          >
            About the Author
          </Title>
          {author && (
            <div className="block w-40 mx-auto sm:mx-0 sm:h-fit sm:max-w-[10rem] md:max-w-none sm:w-auto mb-10 sm:mb-0 relative">
              <GatsbyImage
                image={author}
                alt={image.alt}
                objectFit="contain"
                objectPosition="center"
                className="clipped-hexagon z-0 w-full h-full mb-0 sm:pb-0 order-2 sm:order-1 md:col-start-1 md:row-start-1"
              />
              <BackgroundImage
                {...bg}
                className="w-full h-full z-1 top-0 left-0 mb-0 sm:pb-0 order-2 sm:order-1 md:col-start-1 md:row-start-1"
                style={{ position: "absolute" }}
              />
            </div>
          )}
          <div className="flex w-full sm:my-4 justify-center gap-4 md:mt-0 md:gap-6 flex-col-reverse sm:flex-col md:col-start-2 md:row-start-1 md:mb-0 md:pl-10  md:pt-10">
            <Title
              {...{
                type: 2,
                className: "hidden sm:block my-0 text-3xl sm:text-4xl ",
                color: "white",
              }}
            >
              About the Author
            </Title>
            <Paragraph
              {...{
                className:
                  "!text-primary !text-xl my-0 uppercase text-center sm:text-left",
              }}
            >
              {name}
            </Paragraph>

            {socials && (
              <div className="flex flex-row flex-wrap items-center justify-center sm:justify-start gap-6">
                <SocialLinks
                  {...{
                    socials: socials.map(
                      (social: { icon: { url: string }; link: string }) => ({
                        icon: social.icon.url,
                        link: social.link,
                      })
                    ),
                    className: "mt-0 sm:mt-8 lg:mt-0",
                    config: { brandingSocialFrame },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {blurb && (
        <Paragraph
          {...{
            className:
              " w-11/12 lg:max-w-screen-lg mx-auto mt-4 mb-0 block text-center sm:text-left px-6",
            color: "slateGrey",
          }}
        >
          {blurb}
        </Paragraph>
      )}
    </div>
  );
};

export default AuthorCard;
