import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

import "./styles.scss";
import { IStructuredGallery } from "gatsby-node";

interface IStructuredGalleryProps extends IStructuredGallery {
  className: string;
}

const StructuredGallery: React.FC<IStructuredGalleryProps> = ({
  images = [],
  className,
}) => {
  return (
    <div
      {...{
        className: cx(
          "max-w-screen-xl container grid grid-cols-3 gap-4 mx-auto",
          className
        ),
      }}
    >
      {images &&
        images.map((img, i) => {
          const image = getImage(img);
          return (
            <div className="w-full ">
              {image && <GatsbyImage image={image} alt={img.alt} />}
            </div>
          );
        })}
    </div>
  );
};

export default StructuredGallery;
