import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import cx from "classnames";
import { IStructuredImage } from "gatsby-node";
interface IStructuredImageProps extends IStructuredImage {
  className: string;
  alt: string;
}
const StructuredImage: React.FC<IStructuredImageProps> = ({
  image,
  alt,
  className,
}) => {
  const asset = getImage(image);

  return (
    <div className={cx("relative", className)}>
      {asset && <GatsbyImage image={asset} alt={alt} />}
      {image.title && (
        <span className="block max-w-[175px] text-left xl:absolute mt-4 xl:mt-0 lg:-right-52 bottom-0 font-body italic text-offwhite">
          {image.title}
        </span>
      )}
    </div>
  );
};

export default StructuredImage;
