import MediumNewsCard from "@components/NewsCards/Medium";
import cx from "classnames";
import BackgroundImage from "gatsby-background-image";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React, { useRef, useCallback } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import nextIcon from "@images/icn-next.svg";

import SVG from "@components/SVG";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface ILatestArticlesProps {
  block: {
    __typename: string;
    body: string;
    title: string;
    background: IGatsbyImageData;
    articles: {
      locale: string;
      slug: string;
      title: string;
      heroImage: IGatsbyImageData;
      excerpt: string;
      meta: { firstPublishedAt: string };
      category: { name: string };
    }[];
  };
  className: string;
}

const LatestArticles: React.FC<ILatestArticlesProps> = ({
  block: { __typename, body, title, background, articles: items },
  className,
}) => {
  const sliderRef: React.MutableRefObject<any> = useRef(null);
  const bg = convertToBgImage(getImage(background));

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div
      {...{
        className: cx("max-w-screen-xl mx-auto px-12", className),
      }}
    >
      {bg ? (
        <BackgroundImage
          {...bg}
          className={`w-full h-full absolute top-0 left-0 bg-contains bg-top -z-10`}
          style={{ position: "absolute" }}
        />
      ) : null}
      <div className="w-full relative max-w-lg mx-auto md:max-w-none">
        <Swiper
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: "#prev-slide",
            nextEl: "#next-slide",
          }}
          spaceBetween={20}
          breakpoints={{
            576: {
              // width: 576,
              slidesPerView: 1,
            },
            768: {
              // width: 768,
              slidesPerView: 3,
            },
          }}
          pagination={{
            clickable: true,
          }}
          //   navigation
          className="!pb-12"
        >
          {items &&
            items.map((item, i) => {
              return (
                <SwiperSlide>
                  <MediumNewsCard {...{ ...item }} />
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div
          className="absolute z-10 left-[-1.5rem] top-[calc(48%-0.75rem)] rotate-180 cursor-pointer"
          id="prev-slide"
        >
          <SVG
            src={nextIcon}
            className="w-8 h-8 text-seaSerpant group-hover:text-white transition-all duration-300"
          />
        </div>
        <div
          className="absolute z-10 right-[-1.5rem] top-[calc(48%-0.75rem)] cursor-pointer"
          id="next-slide"
        >
          <SVG
            src={nextIcon}
            className="w-8 h-8 text-seaSerpant group-hover:text-white transition-all duration-300"
          />
        </div>
      </div>
    </div>
  );
};

export default LatestArticles;
