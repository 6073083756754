import BackgroundImage from "gatsby-background-image";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React, { BaseSyntheticEvent, useState } from "react";

import Paragraph from "@components/Paragraph";
import SVG from "@components/SVG";
import facebook from "@images/icn-facebook.svg";
import shareLink from "@images/icn-sharelink.svg";
import twitter from "@images/icn-twitter.svg";
import cx from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ISocialShareLinksProps {
  className: string;
  frameImage: IGatsbyImageData;
}

const SocialShareLinks: React.FC<ISocialShareLinksProps> = ({
  className,
  frameImage,
}) => {
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const bg = convertToBgImage(getImage(frameImage));
  const _window = typeof window !== "undefined";
  const handleSocialClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const url = e?.currentTarget?.href;
    const w = 600;
    const h = 400;

    if (url && _window) {
      window.open(
        url,
        "_blank",
        `
                scrollbars=yes,
                width=${w}, 
                height=${h}, 
                top=${window?.innerHeight / 2 - h / 2}, 
                left=${window?.innerWidth / 2 - w / 2}
            `
      );
    }
  };

  return (
    <div
      className={cx(
        `flex flex-col md:flex-row items-center justify-center text-center`,
        className
      )}
    >
      <Paragraph
        {...{
          className: "mr-0 mb-4 md:mb-0 md:mr-4 text-lg text-center uppercase",
          color: "white",
        }}
      >
        Share this Article
      </Paragraph>
      {_window && (
        <div className="flex items-center justify-between w-52">
          <a
            href={`https://www.facebook.com/sharer.php?u=${window?.location?.href}`}
            target="_blank"
            rel="noopener nofollow noreferrer"
            className="flex items-center justify-center font-bold ml-0 md:ml-4 relative"
            onClick={handleSocialClick}
          >
            <div className="block w-14 h-14 relative">
              <BackgroundImage
                {...bg}
                Tag="div"
                preserveStackingContext
                className="w-full h-full absolute top-0 left-0 z-0 pointer-events-none select-none"
                style={{ position: "absolute" }}
              />

              <SVG
                src={facebook}
                className={`w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-white hover:fill-seaSerpant transition duration-300`}
              />
            </div>
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${
              window?.location?.href
            }&text=${""}`}
            target="_blank"
            rel="noopener nofollow noreferrer"
            className="flex items-center justify-center font-bold ml-4 relative"
            onClick={handleSocialClick}
          >
            <div className="block w-14 h-14 relative">
              <BackgroundImage
                {...bg}
                Tag="div"
                preserveStackingContext
                className="w-full h-full absolute top-0 left-0 z-0 pointer-events-none select-none"
                style={{ position: "absolute" }}
              />

              <SVG
                src={twitter}
                className={`w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-white hover:fill-seaSerpant transition duration-300`}
              />
            </div>
          </a>
          <div className="flex items-center justify-center font-bold ml-4 relative">
            <CopyToClipboard
              text={window?.location?.href}
              onCopy={() => setLinkCopied(true)}
            >
              <div className="block w-14 h-14 relative cursor-pointer">
                <BackgroundImage
                  {...bg}
                  Tag="div"
                  preserveStackingContext
                  className="w-full h-full absolute top-0 left-0 z-0 pointer-events-none select-none"
                  style={{ position: "absolute" }}
                />

                <SVG
                  src={shareLink}
                  className={`w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white hover:text-seaSerpant transition duration-300`}
                />
              </div>
            </CopyToClipboard>
            {linkCopied && (
              <Paragraph
                {...{
                  className:
                    "mr-0 ml-4 mb-0 md:mr-4 text-lg text-center absolute -right-20",
                  color: "white",
                }}
              >
                Copied!
              </Paragraph>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialShareLinks;
