import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StructuredText } from "react-datocms";
import {
  renderRule,
  isHeading,
  isParagraph,
} from "datocms-structured-text-utils";
import cx from "classnames";

import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import { IStructuredImageText } from "gatsby-node";

interface IStructuredImageTextProps extends IStructuredImageText {
  className: string;
  alt: string;
}

const StructuredImageText: React.FC<IStructuredImageTextProps> = ({
  image,
  alt,
  body,
  className,
}) => {
  const img = getImage(image);
  return (
    <div className={cx("relative", className)}>
      <div className="flex flex-col items-stretch gap-6">
        {img && (
          <GatsbyImage
            image={img}
            alt={alt}
            aspectRatio={16 / 9}
            className="h-10/12 order-1"
          />
        )}
        <div className="order-1">
          <StructuredText
            data={body}
            customRules={[
              renderRule(isHeading, ({ node, children, key }) => {
                return (
                  <Title
                    {...{
                      key,
                      color: node.level === 1 ? "black" : "primary",
                      type: node.level,
                      size: `${8 - node.level}xl`,
                      className: `${node.level === 1 ? "mt-0" : "mb-0"}`,
                    }}
                  >
                    {children}
                  </Title>
                );
              }),
              renderRule(isParagraph, ({ node, children, key }) => {
                return (
                  <Paragraph
                    {...{
                      key,
                      color: "slateGrey",
                      className: "mb-8",
                    }}
                  >
                    {children}
                  </Paragraph>
                );
              }),
            ]}
          />
        </div>
      </div>
      {image.title && (
        <span className="block max-w-[250px] xl:max-w-[175px] text-left xl:absolute mt-4 xl:mt-0 xl:-left-44 bottom-0 font-body italic order-3 text-offwhite">
          {image.title}
        </span>
      )}
    </div>
  );
};

export default StructuredImageText;
