import React from "react";
import cx from "classnames";
import { IStructuredLinebreak } from "gatsby-node";

interface IStructuredLinebreakProps extends IStructuredLinebreak {
  className: string;
}

const StructuredLineBreak: React.FC<IStructuredLinebreakProps> = ({
  className,
}) => {
  return (
    <div className={cx("border-t border-dashed border-offwhite", className)} />
  );
};

export default StructuredLineBreak;
