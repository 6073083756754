import { graphql } from "gatsby";
import * as React from "react";

import Title from "@components/Title";
import Default from "@hoc/Default";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { StructuredText } from "react-datocms";
const { formatSocials } = require("../utils");

import AuthorCard from "@components/AuthorCard";
import Button from "@components/Button";
import LatestArticles from "@components/LatestArticles";
import Paragraph from "@components/Paragraph";
import SocialShareLinks from "@components/SocialShareLinks";
import {
  StructuredGallery,
  StructuredImage,
  StructuredImageText,
  StructuredLineBreak,
  StructuredList,
  StructuredQuote,
} from "@components/Structured";
import {
  isHeading,
  isList,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import latestArticlesBackground from "@images/bkg-latest-article-baked.jpg";
import Meta from "@hoc/Meta";
import { IConfig } from "gatsby-node";

interface INewsPostProps {
  pageContext: {
    config: IConfig;
    node: {
      id: string;
      slug: string;
      locale: string;
    };
  };
  data: {
    posts: {
      nodes: {
        seo: {
          description: string;
          title: string;
          twitterCard: any; // TODO types
          image: {
            url: string;
          };
        };
        id: string;
        meta: {
          firstPublishedAt: string;
        };
        heroImage: {
          gatsbyImageData: IGatsbyImageData;
        };
        slug: string;
        title: string;
        excerpt: string;
      }[];
    }[];
  };
}

const NewsPost: React.FC<INewsPostProps> = ({ pageContext, data }) => {
  const config = pageContext.config;
  config.socials = formatSocials(config);
  const heroBackground = getImage(pageContext.node.heroImage);
  const heroBg = convertToBgImage(heroBackground);
  const latestArticlesBackground = getImage(
    pageContext.node.latestArticlesBackgroundImage
  );
  const latestArticlesBg = convertToBgImage(latestArticlesBackground);

  return (
    <Default {...{ config }}>
      <Meta {...{ seo: pageContext?.node?.seo }} />
      <BackgroundImage
        Tag="section"
        {...heroBg}
        preserveStackingContext
        className=""
      >
        <div className="bg-richBlack opacity-40 h-full w-full absolute top-0 left-0 z-10" />
        <div className="w-11/12 mx-auto h-full flex items-baseline  pb-0 pt-40 lg:pt-48 md:pb-40 flex-col relative z-20">
          <div className="flex items-middle mb-8 md:mb-14">
            {pageContext.node.category &&
              pageContext.node.category.categoryName && (
                <div className="h-[34px] mr-6 relative category-container-clip">
                  <div className="flex justify-center items-center border-2 border-slateGrey w-[140px] h-[34px] bg-white bg-opacity-10 text-primary">
                    {pageContext.node.category.categoryName}
                  </div>
                  <span className="-top-[22px] -left-[25px] rotate-[52deg] absolute h-10 w-10 bg-slateGrey"></span>
                  <span className="-bottom-[22px] -left-[25px] rotate-[37deg] absolute h-10 w-10 bg-slateGrey"></span>
                  <span className="-top-[22px] -right-[25px] rotate-[37deg] absolute h-10 w-10 bg-slateGrey"></span>
                  <span className="-bottom-[22px] -right-[25px] rotate-[52deg] absolute h-10 w-10 bg-slateGrey"></span>
                </div>
              )}
            <span className=" font-body text-white tracking-widest self-center">
              Published: {pageContext.node.meta.firstPublishedAt}
            </span>
          </div>
          <Title
            {...{
              color: "white",
              type: 1,
              className:
                "mt-0 mb-14 font-bold text-4xl lg:text-6xl xl:text-7xl",
              bgGlow: true,
            }}
          >
            {pageContext.node.title}
          </Title>

          <SocialShareLinks
            frameImage={pageContext.config.brandingSocialFrame}
            className="hidden md:flex"
          />
        </div>
      </BackgroundImage>

      <section className="flex justify-center items-center md:hidden bg-richBlack py-12">
        <SocialShareLinks frameImage={pageContext.config.brandingSocialFrame} />
      </section>

      <section className="py-6 md:py-10 pb- md:pb-48 overflow-hidden  bg-richBlack">
        <div className="w-11/12 mx-auto max-w-[1060px] relative z-30 mb-32">
          <StructuredText
            data={pageContext.node.newsContent}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsStructuredImage":
                  return (
                    <StructuredImage
                      {...{
                        image: record.image,
                        alt: record.image.alt,
                        className: "max-w-screen-lg w-full mb-8 mt-10 md:mt-14",
                      }}
                    />
                  );
                case "DatoCmsArticleLinebreak":
                  return (
                    <StructuredLineBreak
                      {...{
                        className: "max-w-screen-2xl mx-auto w-11/12 mb-8",
                      }}
                    />
                  );
                case "DatoCmsStructuredGallery":
                  return (
                    <StructuredGallery
                      {...{
                        images: record.images,
                        className: "mb-8",
                      }}
                    />
                  );
                case "DatoCmsStructuredImageText":
                  return (
                    <StructuredImageText
                      {...{
                        image: record.image,
                        alt: record.image.alt,
                        body: record.body,
                        reverse: record.reverseOrder,
                        className: "max-w-screen-lg mb-8 mt-10 md:mt-14 w-full",
                      }}
                    />
                  );
                case "DatoCmsStructuredQuote":
                  return (
                    <StructuredQuote
                      {...{
                        quote: record.quote,
                        quoteImgBackground: config.brandingSocialFrame,
                        className: "text-center mb-8 w-full",
                      }}
                    />
                  );
                default:
                  return null;
              }
            }}
            customRules={[
              renderRule(isHeading, ({ node, children, key }) => (
                <Title
                  {...{
                    key,
                    type: node.level,
                    className: `${
                      node.level === 1
                        ? "mb-10 md:mb-14 mt-10 md:mt-14"
                        : node.level === 2
                        ? "mb-7 md:mb-20 mt-10 md:mt-14"
                        : "mb-4 md:mb-5 mt-10 md:mt-14"
                    }`,
                    color: "white",
                  }}
                >
                  {children}
                </Title>
              )),
              renderRule(isParagraph, ({ node, children, key }) => (
                <Paragraph
                  {...{
                    key,
                    color: "slateGrey",
                    className: "",
                  }}
                >
                  {children}
                </Paragraph>
              )),
              renderRule(isList, ({ node, children, adapter, key }) => (
                <StructuredList
                  {...{
                    node,
                    adapter,
                    key,
                    className:
                      "text-slateGrey max-w-3xl w-11/12 mb-12 lg:mb-16",
                  }}
                >
                  {children}
                </StructuredList>
              )),
            ]}
          />
          {pageContext.node.newsAuthor && (
            <AuthorCard
              {...{
                name: pageContext.node.newsAuthor.authorName,
                email: pageContext.node.newsAuthor.authorEmail,
                socials: pageContext.node.newsAuthor.socialMedia,
                image: pageContext.node.newsAuthor.authorImage,
                blurb: pageContext.node.newsAuthor.authorBlurb,
                brandingSocialFrame: config.brandingSocialFrame,
                className: "lg:max-w-screen-lg mx-auto",
              }}
            />
          )}
        </div>
        <div className="flex justify-between w-11/12 mx-auto max-w-[1060px] relative z-30 border-t border-slateGrey">
          <Button
            {...{
              to: "/",
              color: "primary",
              link: true,
              external: false,
              className:
                "overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center mt-6 mr-2 md:mr-6 w-fit mx-auto md:mx-0 md:px-10 py-3 decoration-none uppercase tracking-wider bg-black",
            }}
          >
            Back to home
          </Button>
          <Button
            {...{
              clickHandler: () =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                }),
              type: "button",
              color: "white",
              className:
                "overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center mt-6 ml-2 md:mr-6 w-fit mx-auto md:mx-0 md:px-10 py-3 decoration-none uppercase tracking-wider bg-black",
            }}
          >
            Back to top
          </Button>
        </div>
      </section>
      <div className="clipped-latest-articles w-full h-12 lg:h-12 relative bg-richBlack z-20 -top-1" />
      <section className="overflow-hidden bg-light relative pt-20 md:pt-32 -mt-12">
        <Title {...{ type: 2, className: "text-4xl text-center z-10 mx-auto" }}>
          Latest Articles
        </Title>
        <LatestArticles
          {...{
            block: {
              title: "Latest Articles",
              articles: data.posts.nodes,
              background: latestArticlesBackground,
              locale: pageContext.node.locale,
            },
            className: "py-16 md:py-20 pb-28",
          }}
        />
      </section>
    </Default>
  );
};

export default NewsPost;

export const query = graphql`
  query getPosts($locale: String) {
    posts: allDatoCmsNews(
      filter: { locale: { eq: $locale } }
      sort: { order: DESC, fields: meta___createdAt }
      limit: 3
    ) {
      nodes {
        seo {
          description
          title
          twitterCard
          image {
            url
          }
        }
        id
        meta {
          firstPublishedAt(formatString: "DD MMMM, YYYY")
        }
        heroImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        slug
        title
        excerpt
      }
    }
  }
`;
